import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Banks from "../../Container/banksContainer";
import CashBook from "../../Container/cashbookContainer";
import { ToastContainer } from "react-toastify";

function Account_page({ current_user }) {
  const tabs = ["banks", "cashbook"];
  var current_tab = localStorage.getItem("activeTab");
  if (!tabs.includes(current_tab)) {
    current_tab = "banks";
  }

  const [activeTab, setActiveTab] = useState(current_tab);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Tabs
        defaultActiveKey={activeTab}
        transition={true}
        onSelect={handleTabSelect}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab eventKey="banks" title="Banks">
          {activeTab === "banks" && <Banks />}
        </Tab>

        <Tab eventKey="cashbook" title="CashBook">
          {activeTab === "cashbook" && <CashBook />}
        </Tab>
      </Tabs>

      <ToastContainer autoClose={2000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default Account_page;
