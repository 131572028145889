import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import numberToWords from "number-to-words";
import "./sale_invoice.css";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import RoomIcon from "@material-ui/icons/Room";
import DraftsIcon from "@material-ui/icons/Drafts";

function Invoice(props) {
  const [company, setcompany] = useState(
    JSON.parse(localStorage.getItem("current_user"))
  );
  const response = JSON.parse(localStorage.getItem("data"));
  const [data, setdata] = useState(response);

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printclass",
    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (data) {
      handleprint();
    }
  }, [data]);

  const slicedata = (date) => {
    const mydate = date.split("-");
    return `${mydate[2]}/${mydate[1]}/${mydate[0]}`;
  };

  return (
    <div className="mydiv p-3" ref={componentRef}>
      {data && (
        <>
          <div className="d-flex">
            <div className="col-4 text-start">
              <img
                src={company?.profile?.logo}
                alt="logo"
                width={"200"}
                height={"100"}
              ></img>
            </div>

            <div className="col-4 text-center">
              <h5 className="mb-2 text-center">
                <strong>{company?.profile?.title}</strong>
              </h5>
              <h6 className="mb-2 text-center">
                Regestration #{" "}
                <strong>{company?.profile?.registration_no}</strong>
              </h6>
            </div>

            <div className="col-4 ms-2 text-center">
              <p className=" m-0 " style={{ fontSize: "0.8rem" }}>
                <strong>Address :</strong> {company?.profile?.address}
              </p>

              <p className="m-0 " style={{ fontSize: "0.8rem" }}>
                <strong>Contact : </strong>
                {company?.profile?.phone_number}
              </p>

              <p className=" m-0 " style={{ fontSize: "0.8rem" }}>
                <strong>Mail : </strong>
                {company?.email}
              </p>

              <p className=" m-0 " style={{ fontSize: "0.8rem" }}>
                <strong>Links : </strong>
                {company?.profile?.social_links}
              </p>
            </div>
          </div>

          <h4 className="text-center">
            <strong className="ps-3 pe-3 border-bottom border-dark ">
              Contract
            </strong>
          </h4>

          <div className="d-flex mt-3 ">
            <div className="col-8 me-2 ">
              <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                <div className="col-4">Booking No : </div>
                <strong className="col-8">{data.booking_number}</strong>
              </p>

              <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                <div className="col-4">Car Registration : </div>
                <strong className="col-8">
                  {data.product_info.registration_no}
                </strong>
              </p>
            </div>
            <div className="col-4 ">
              <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                <div className="col-4">Date : </div>
                <strong className="col-8">{data.date}</strong>
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between  mt-3">
            <p className="m-0" style={{ fontSize: "0.8rem" }}>
              Make :<strong className="ms-2">{data.product_info?.make}</strong>
            </p>
            <p className="m-0" style={{ fontSize: "0.8rem" }}>
              Color :
              <strong className="ms-2">{data.product_info?.color}</strong>
            </p>
            <p className="m-0" style={{ fontSize: "0.8rem" }}>
              Type :<strong className="ms-2">{data.product_info?.type}</strong>
            </p>
            <p className="m-0" style={{ fontSize: "0.8rem" }}>
              Booking Type :
              <strong className="ms-2">{data.booking_type}</strong>
            </p>
          </div>

          <div className="border border-dark pe-2 ps-2 ">
            <h4 className="text-center">
              <strong className="ps-3 pe-3 border-bottom border-dark ">
                Hire Person Data
              </strong>
            </h4>

            <div className="d-flex mt-3 ">
              <div className="col-6 me-2 ">
                <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                  <div className="col-4">Name : </div>
                  <strong className="col-8">{data.customer_info.name}</strong>
                </p>

                <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                  <div className="col-4">N.I.C # : </div>
                  <strong className="col-8">{data.customer_info.cnic}</strong>
                </p>
              </div>
              <div className="col-6 ">
                <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                  <div className="col-4">Phone No : </div>
                  <strong className="col-8">
                    {data.customer_info.contact}
                  </strong>
                </p>
                <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                  <div className="col-4">Driving License : </div>
                  <strong className="col-8">
                    {data.customer_info.license_no}
                  </strong>
                </p>
              </div>
            </div>

            <div className=" mt-2 ">
              <p className=" m-0" style={{ fontSize: "0.8rem" }}>
                Hire Person Address :
                <strong className="ms-2">{data.customer_info.address}</strong>
              </p>
            </div>

            <div className="d-flex justify-content-between col-8 mt-2">
              <p className="col-4 m-0" style={{ fontSize: "0.8rem" }}>
                Total :<strong className="ms-2">{data.total}</strong>
              </p>
              <p className="col-4 m-0" style={{ fontSize: "0.8rem" }}>
                Received :
                <strong className="ms-2">{data.amount_recieved}</strong>
              </p>
              <p className="col-4 m-0" style={{ fontSize: "0.8rem" }}>
                Balance :
                <strong className="ms-2">
                  {data.total - data.amount_recieved}
                </strong>
              </p>
            </div>

            <div className="d-flex justify-content-between col-8 mt-2">
              <p className="col-4 m-0" style={{ fontSize: "0.8rem" }}>
                Mileage Meter Reading :
              </p>
              <p className="col-4 m-0" style={{ fontSize: "0.8rem" }}>
                Departure :
                <strong className="ms-2">{data.departure_reading}</strong>
                <strong className="ms-2">Km</strong>
              </p>
              <p className="col-4 m-0" style={{ fontSize: "0.8rem" }}>
                Arrival :
                <strong className="ms-2">{data.arrival_reading}</strong>
                <strong className="ms-2">Km</strong>
              </p>
            </div>

            <div className="d-flex justify-content-between col-8 mt-2">
              <p className="col-5 m-0" style={{ fontSize: "0.8rem" }}>
                Date of Departure :
                <strong className="ms-2">{data.from_date}</strong>
              </p>
              <p className="col-4 m-0" style={{ fontSize: "0.8rem" }}>
                Date of Arrival :
                <strong className="ms-2">{data.to_date}</strong>
              </p>
            </div>

            <div className="d-flex justify-content-between col-8 mt-2">
              <p className="col-5 m-0" style={{ fontSize: "0.8rem" }}>
                Destination :
                <strong className="ms-2">{data.destination}</strong>
              </p>
            </div>

            <div className="d-flex justify-content-between col-8 mt-2 mb-2">
              <p className="col-10 m-0" style={{ fontSize: "0.8rem" }}>
                <strong>
                  <span className="me-2 border-bottom border-dark ps-2 pe-2">
                    Notes :
                  </span>
                  {data.destination}
                </strong>
              </p>
            </div>
          </div>

          <div className="border border-top-0 border-dark pe-2 ps-2 ">
            <h4 className="text-center">
              <strong className="ps-3 pe-3 border-bottom border-dark ">
                Guaranteed Person Data
              </strong>
            </h4>

            <div className="d-flex mt-3 ">
              <div className="col-6 me-2 ">
                <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                  <div className="col-4">Guarantor Name : </div>
                  <strong className="col-8">
                    {data.reference_person_info.name}
                  </strong>
                </p>
              </div>

              <div className="col-6 ">
                <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                  <div className="col-4">N.I.C #: </div>
                  <strong className="col-8">
                    {data.reference_person_info.cnic}
                  </strong>
                </p>
              </div>
            </div>

            <div className=" mt-3 ">
              <p className="d-flex m-0" style={{ fontSize: "0.8rem" }}>
                <div className="me-2">Guarantor Person Address : </div>
                <strong>{data.reference_person_info.address}</strong>
              </p>
            </div>

            <div className="d-flex justify-content-between col-8 mt-2">
              <p className="col-4 m-0" style={{ fontSize: "0.8rem" }}>
                Cell :
                <strong className="ms-2">
                  {data.reference_person_info.contact}
                </strong>
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-5">
            <p
              className="col-4 m-0 text-start"
              style={{ borderTop: "1px solid black" }}
            >
              Hirer's Signature
            </p>

            <p
              className="col-4 m-0 text-start"
              style={{ borderTop: "1px solid black" }}
            >
              Guarantor's Signature
            </p>
          </div>

          <div className="col-8 mt-1">
            <pre className="">{company?.profile?.terms}</pre>
          </div>
          <hr className="m-0" />
          <hr className="m-0 mt-1 mb-1" style={{ opacity: "1" }} />
          <hr className="m-0" />
          {/* #################################################### */}
        </>
      )}
    </div>
  );
}

export default Invoice;
