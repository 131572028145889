import { connect } from "react-redux";
import Defaulters from "../Components/defaluters/defaulters";
import { Settablehistory, removeuser } from "../Service/Actions/action";

const dispatchpart = (dispatch) => ({
  Settable_history: (data) => dispatch(Settablehistory(data)),
  RemoveUser: () => dispatch(removeuser()),
});

const getpart = (state) => ({
  state,
});

export default connect(getpart, dispatchpart)(Defaulters);
