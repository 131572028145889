import React, { useState, useEffect, useRef } from "react";
import Widget from "./widget";
import "./dashboard.css";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import TrendingDownOutlinedIcon from "@material-ui/icons/TrendingDownOutlined";
import { ResponsiveContainer } from "recharts";
import {
  LineChart,
  Line,
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  Cell,
  ComposedChart,
  LabelList,
  Brush,
  ErrorBar,
} from "recharts";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import {
  DateRangePicker,
  DefinedRange,
  defaultStaticRanges,
  staticRanges,
} from "react-date-range";
import Overlay from "react-bootstrap/Overlay";
import Tooltipp from "react-bootstrap/Tooltip";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";

function Dashboard(props) {
  const { t } = useTranslation();
  const [dashboard_data, setdashboard_data] = useState({
    vehicles: 0,
    booking: 0,
    payments: 0,
    expenses: 0,
    balance: 0,
    cash: 0,
  });
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const dispatch = props.Settable_history;

  const [lastyeardata, setlastyeardata] = useState([]);

  const [lastmonthdata, setlastmonthdata] = useState([]);
  const [outbookings, setoutbookings] = useState([]);
  const [pendingbookings, setpendingbookings] = useState([]);

  const [start_date, setstart_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [end_date, setend_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [date_range, setdate_range] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      showDateDisplay: "false",
    },
  ]);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "dashboard" });
  }, []);

  const handleSelect = (item) => {
    const get_date = item.selection;
    setdate_range([item.selection]);
    setstart_date(get_date.startDate.toISOString().substring(0, 10));
    setend_date(get_date.endDate.toISOString().substring(0, 10));
    if (
      get_date.startDate.toISOString().substring(0, 10) !==
      get_date.endDate.toISOString().substring(0, 10)
    ) {
      setshow(!show);
    }
  };

  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    if (value !== 0) {
      return (
        <text
          x={x + width / 2}
          y={y}
          fill="#666"
          textAnchor="middle"
          dy={-6}
          fontWeight={"bold"}
        >
          {value}
        </text>
      );
    }
  };

  const handleselectiochange = (e) => {
    setshow(!show);
    setTarget(e.target);
  };

  useEffect(() => {
    const fetchpendingbookings = async () => {
      var url = `${route}/api/pending-bookings/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });

      const json = await response.json();

      if (response.ok) {
        setpendingbookings(json);
      }
    };

    const fetchoutbookings = async () => {
      var url = `${route}/api/out-bookings/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });

      const json = await response.json();

      if (response.ok) {
        setoutbookings(json);
      }
    };

    const fetchyearlybookings = async () => {
      var url = `${route}/api/last-month-booking/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });

      const json = await response.json();

      if (response.ok) {
        const optimize = json.dates.map((item, index) => {
          const booking = parseFloat(json.total_booking[index].toFixed(1));
          const amount = parseFloat(json.total_amount[index].toFixed(1));

          return { name: item, amount: amount, bookings: booking };
        });
        setlastmonthdata(optimize);
      }
    };

    const fetchmonthlybookings = async () => {
      var url = `${route}/api/last-year-booking/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });

      const json = await response.json();

      if (response.ok) {
        const optimize = json.months.map((item, index) => {
          const booking = parseFloat(json.total_booking[index].toFixed(1));
          const amount = parseFloat(json.total_amount[index].toFixed(1));
          return { name: item, amount: amount, bookings: booking };
        });
        setlastyeardata(optimize);
      }
    };

    if (current_user) {
      fetchoutbookings();
      fetchpendingbookings();
      fetchmonthlybookings();
      fetchyearlybookings();
    }
  }, [current_user]);

  useEffect(() => {
    const fetchdashboarddata = async () => {
      var url = `${route}/api/dashboard/?user_id=${current_user.id}&start_date=${start_date}&end_date=${end_date}`;
      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });

      const json = await response.json();

      if (response.ok) {
        setdashboard_data(json.resut);
      }
    };

    if (current_user) {
      fetchdashboarddata();
    }
  }, [current_user, date_range]);

  const DataFormater = (number) => {
    const num = parseInt(number);
    if (num > 1000000000) {
      return (num / 1000000000).toString() + "B";
    } else if (num > 1000000) {
      return (num / 1000000).toString() + "M";
    } else if (num > 1000) {
      return (num / 1000).toString() + "K";
    } else {
      return num.toString();
    }
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-end pe-2">
        <div className=" col-5 col-md-2">
          {date_range[0].endDate.getFullYear() -
            date_range[0].startDate.getFullYear() ===
          10 ? (
            <TextField
              ref={ref}
              type="button"
              style={{
                backgroundColor: "rgb(241, 245, 245)",
              }}
              className="form-control  mb-3"
              label={t("date")}
              value="From Start"
              onClick={handleselectiochange}
              size="small"
            />
          ) : (
            <TextField
              ref={ref}
              type="button"
              style={{
                backgroundColor: "rgb(241, 245, 245)",
              }}
              className="form-control  mb-3 "
              label={t("date")}
              value={`${date_range[0].startDate
                .toLocaleString("en-GB")
                .substring(0, 10)} - ${date_range[0].endDate
                .toLocaleString("en-GB")
                .substring(0, 10)}`}
              onClick={handleselectiochange}
              size="small"
            />
          )}
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref}
          >
            {(props) => (
              <Tooltipp id="overlay-example" {...props}>
                <div>
                  <DefinedRange
                    onChange={handleSelect}
                    showSelectionPreview={true}
                    showCalendarPreview={false}
                    dragSelectionEnabled={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={date_range}
                    direction="vertcal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                    staticRanges={[
                      ...defaultStaticRanges,
                      {
                        label: "Last Year",
                        range: () => ({
                          startDate: startOfYear(addYears(new Date(), -1)),
                          endDate: endOfYear(addYears(new Date(), -1)),
                        }),
                        isSelected(range) {
                          const definedRange = this.range();
                          return (
                            isSameDay(
                              range.startDate,
                              definedRange.startDate
                            ) && isSameDay(range.endDate, definedRange.endDate)
                          );
                        },
                      },
                      {
                        label: "This Year",
                        range: () => ({
                          startDate: startOfYear(new Date()),
                          endDate: endOfDay(new Date()),
                        }),
                        isSelected(range) {
                          const definedRange = this.range();
                          return (
                            isSameDay(
                              range.startDate,
                              definedRange.startDate
                            ) && isSameDay(range.endDate, definedRange.endDate)
                          );
                        },
                      },
                      {
                        label: "From Start",
                        range: () => ({
                          startDate: startOfYear(addYears(new Date(), -10)),
                          endDate: endOfDay(new Date()),
                        }),
                        isSelected(range) {
                          const definedRange = this.range();
                          return (
                            isSameDay(
                              range.startDate,
                              definedRange.startDate
                            ) && isSameDay(range.endDate, definedRange.endDate)
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </Tooltipp>
            )}
          </Overlay>
        </div>
      </div>

      <div className="row  p-2 pb-0">
        <div className="col-6 col-sm-2 mb-1">
          <Widget
            text_1={"Vehicles"}
            value_1={parseInt(dashboard_data.vehicles)}
            icon_widget={<ShoppingCartOutlinedIcon fontSize="inherit" />}
            link="/products"
            color="#28a745"
          />
        </div>

        <div className="col-6 col-sm-2">
          <Widget
            text_1={"Bookings"}
            value_1={parseInt(dashboard_data.booking)}
            icon_widget={<AttachMoneyOutlinedIcon fontSize="inherit" />}
            link="/booking"
            color="#17a2b8"
          />
        </div>

        <div className="col-6 col-sm-2 mb-1">
          <Widget
            text_1={"Payments"}
            value_1={parseInt(dashboard_data.payments)}
            icon_widget={<AssessmentOutlinedIcon fontSize="inherit" />}
            link="/bookings"
            color="#dc3545"
          />
        </div>

        <div className="col-6 col-sm-2 mb-1">
          <Widget
            text_1={"Expenses"}
            value_1={parseInt(dashboard_data.expenses)}
            icon_widget={<LocalOfferOutlinedIcon fontSize="inherit" />}
            link="/expenses"
            color="MediumSeaGreen"
          />
        </div>

        <div className="col-6 col-sm-2  mb-1">
          <Widget
            text_1={"Cash Book"}
            value_1={parseInt(dashboard_data.cash)}
            icon_widget={<LocalAtmIcon fontSize="inherit" />}
            link="/parties"
            color="royalblue"
          />
        </div>

        <div className="col-6 col-sm-2 mb-1">
          <Widget
            text_1={"Balance"}
            value_1={parseInt(dashboard_data.balance)}
            icon_widget={<TrendingDownOutlinedIcon fontSize="inherit" />}
            link="/expenses"
            color="SlateBlue"
          />
        </div>
      </div>

      <div className=" row p-2    mt-3">
        <div className="col-sm-6 mb-3">
          <div className="card">
            <div className=" card-header bg-white">Out Bookings</div>
            <div
              className="card-body"
              style={{ height: "430px", overflow: "auto" }}
            >
              <table className="table w-100">
                <thead>
                  <tr>
                    <th>Booking No</th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th>Vehicle</th>
                    <th>From Date</th>
                  </tr>
                </thead>
                <tbody style={{ height: "320px", overflow: "auto" }}>
                  {outbookings.map((item) => {
                    return (
                      <tr style={{ height: "10px" }}>
                        <td>{item.booking_number}</td>
                        <td>{item.date}</td>
                        <td>{item.customer_name}</td>
                        <td>{item.product_name}</td>
                        <td>{item.from_date}</td>
                      </tr>
                    );
                  })}
                  <tr
                    style={{
                      height: "-40",
                    }}
                  ></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-6 mb-3">
          <div className="card">
            <div className=" card-header bg-white">Pending Bookings</div>
            <div
              className="card-body"
              style={{ height: "430px", overflow: "auto" }}
            >
              <table className="table w-100">
                <thead>
                  <tr>
                    <th>Booking No</th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th>Vehicle</th>
                    <th>From Date</th>
                  </tr>
                </thead>
                <tbody style={{ height: "320px", overflow: "auto" }}>
                  {pendingbookings.map((item) => {
                    return (
                      <tr style={{ height: "10px" }}>
                        <td>{item.booking_number}</td>
                        <td>{item.date}</td>
                        <td>{item.customer_name}</td>
                        <td>{item.product_name}</td>
                        <td>{item.from_date}</td>
                      </tr>
                    );
                  })}
                  <tr
                    style={{
                      height: "-40",
                    }}
                  ></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-sm-12 graph ps-3 ">
        <div className="card ">
          <div className=" card-header bg-white">Last Month Bookings</div>
          <div className=" card-body">
            <ResponsiveContainer height={400} width="100%">
              <LineChart
                data={lastmonthdata}
                margin={{ right: 10, left: 10, bottom: 50 }}
              >
                <CartesianGrid
                  vertical={false}
                  horizontal={false}
                  stroke="gray"
                />
                <XAxis dataKey="name" angle={-45} textAnchor="end" />
                <YAxis
                  allowDataOverflow={false}
                  yAxisId="left"
                  label={{
                    value: "Payments",
                    angle: -90,
                    position: "insideLeft",
                  }}
                  domain={[0, 5000]}
                  tickFormatter={DataFormater}
                />
                <YAxis
                  allowDataOverflow={false}
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: "Bookings",
                    angle: -90,
                    position: "insideRight",
                  }}
                  // domain={[0, 5000]}
                  tickFormatter={DataFormater}
                />

                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                />
                <Line
                  dataKey="amount"
                  type="monotone"
                  stroke="#318fb5"
                  yAxisId="left"
                ></Line>
                <Line
                  dataKey="bookings"
                  type="monotone"
                  stroke="#b0cac7"
                  yAxisId="right"
                ></Line>
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="row col-sm-12 graph ps-3 mt-3">
        <div className="card">
          <div className=" card-header bg-white">Last Year Bookings</div>
          <div className=" card-body">
            <ResponsiveContainer height={400} width="100%">
              <BarChart
                data={lastyeardata}
                margin={{ right: 10, left: 10, bottom: 50 }}
              >
                <CartesianGrid
                  vertical={false}
                  horizontal={false}
                  stroke="gray"
                />
                <XAxis dataKey="name" angle={-45} textAnchor="end" />
                <YAxis
                  allowDataOverflow={false}
                  yAxisId="left"
                  label={{
                    value: "Payments",
                    angle: -90,
                    position: "insideLeft",
                  }}
                  domain={[0, 5000]}
                  tickFormatter={DataFormater}
                />
                <YAxis
                  allowDataOverflow={false}
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: "Bookings",
                    angle: 90,
                    position: "insideRight",
                  }}
                  domain={[0, 10]}
                  tickFormatter={DataFormater}
                />

                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                />

                <Bar
                  dataKey="amount"
                  barSize={20}
                  fill="#318fb5"
                  yAxisId="left"
                  label={renderCustomBarLabel}
                ></Bar>
                <Bar
                  dataKey="bookings"
                  barSize={20}
                  fill="#b0cac7"
                  yAxisId="right"
                  label={renderCustomBarLabel}
                ></Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
