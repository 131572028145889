import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./vehicle.css";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Select from "../alerts/select";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Avatar } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Vehicleform({
  show,
  onHide,
  user,
  route,
  callback,
  current_user,
  check,
  RemoveUser_fun,
}) {
  const inputFile = useRef(null);
  const [Fileurl, setFileurl] = useState([]);
  const [name, setname] = useState("");
  const [status, setstatus] = useState("available");
  const [make, setmake] = useState("");
  const [model, setmodel] = useState("");
  const [registration_no, setregistration_no] = useState("");
  const [type, settype] = useState("");
  const [color, setcolor] = useState("");
  const [isloading, setisloading] = useState(false);
  const [all_images, setall_images] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (current_user) {
      setisloading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("status", status);
      formData.append("make", make);
      formData.append("model", model);
      formData.append("type", type);
      formData.append("color", color);
      formData.append("registration_no", registration_no);

      all_images.forEach((item, index) => {
        formData.append(`images[${index}]picture`, item.picture);
      });

      formData.append("user", current_user.id);

      const response = await fetch(`${route}/api/Vehicles/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
      }

      if (response.ok && check === "false") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        setname("");
        setmake("");
        setcolor("");
        settype("");
        setmodel("");
        setregistration_no("");
        setstatus("");
        setall_images([]);

        setFileurl([]);
      } else if (response.ok && check === "true") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        onHide();
      }
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setall_images([...all_images, { picture: file }]);
      const reader = new FileReader();
      reader.onload = () => {
        setFileurl([...Fileurl, { name: reader.result, url: file }]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = (url, file) => {
    const get_urls = Fileurl.filter((item) => {
      return item.name !== url;
    });
    const backend_url = all_images.filter((item) => {
      return item.picture.name !== file.picture.name;
    });

    setFileurl(get_urls);
    setall_images(backend_url);
  };

  const handleimageclick = (item) => {
    const fileURL = URL.createObjectURL(item);
    window.open(fileURL, "_blank");
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: "0.85" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <ApartmentIcon className="me-2" />
          Add Vehicle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Tabs
            defaultActiveKey="Profile"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Profile" title="Profile">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    size="small"
                    autoFocus
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Model"
                    value={model}
                    onChange={(e) => setmodel(e.target.value)}
                    size="small"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Type"
                    value={type}
                    onChange={(e) => settype(e.target.value)}
                    size="small"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Color"
                    value={color}
                    onChange={(e) => setcolor(e.target.value)}
                    size="small"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Make"
                    value={make}
                    onChange={(e) => setmake(e.target.value)}
                    size="small"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Registration No"
                    value={registration_no}
                    onChange={(e) => setregistration_no(e.target.value)}
                    size="small"
                    required
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Images" title="Images">
              <div className="mb-3">
                <input
                  onChange={handleimageselection}
                  id="select-file"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  ref={inputFile}
                  style={{ display: "none" }}
                />
                <Button onClick={onButtonClick} shadow>
                  Choose file
                </Button>
              </div>
              <div className="d-flex">
                {Fileurl.map((item, index) => {
                  return (
                    <div className="mb-2 me-3 d-flex claas-images" key={index}>
                      <Avatar
                        src={item.name}
                        className="avatar"
                        style={{ width: "130px", height: "130px" }}
                        alt="image"
                        onClick={() => {
                          handleimageclick(item.url);
                        }}
                      />
                      <Badge
                        color="error"
                        overlap="circular"
                        className="badgeepic"
                        badgeContent="X"
                        onClick={() => {
                          handleClick(item.name, all_images[index]);
                        }}
                      ></Badge>
                    </div>
                  );
                })}
              </div>
            </Tab>
          </Tabs>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Vehicleform;
