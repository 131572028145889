import React, { useState, useRef, useEffect } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, Avatar } from "@material-ui/core";
import "../Pages/home.css";
import "./header.css";
import jwtDecode from "jwt-decode";
import { ToastContainer } from "react-toastify";
import custom_toast from "./alerts/custom_toast";
import { useTranslation } from "react-i18next";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { font } from "./ArabicFont";
import Userupdate from "./users/userupdateform";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["Arabic-Font.ttf"] = font;

function Header(props) {
  const { t } = useTranslation();
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const current_user = props.state.Setcurrentinfo.current_user;
  const dispatch = props.Setinfo_ofuser;
  const dispatch_auth = props.SetUser;
  const RemoveUser_fun = props.RemoveUser;
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const [data, setdata] = useState("");
  const [showmodelupdate, setshowmodelupdate] = useState(false);
  const ref = useRef(null);

  const decodedToken = jwtDecode(user.access);
  const userId = decodedToken.user_id;

  useEffect(() => {
    const refreshtoken = async () => {
      if (current_user) {
        const refresh_token = user.refresh;
        const response = await fetch(`${route}/api/token/refresh/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            refresh: user.refresh,
          }),
        });
        const json = await response.json();

        if (!response.ok) {
          if (json.code === "token_not_valid") {
            RemoveUser_fun();
          }
        }

        if (response.ok) {
          json["refresh"] = refresh_token;
          localStorage.setItem("user", JSON.stringify(json));
          dispatch_auth(json);
        }
      }
    };

    const timer = setInterval(refreshtoken, 1000 * 60 * 4.8);
    return () => {
      clearInterval(timer); // Clean up the timer on component unmount
    };
  }, []);

  pdfMake.fonts = {
    ArabicFont: {
      normal: "Arabic-Font.ttf",
      bold: "Arabic-Font.ttf",
      italics: "Arabic-Font.ttf",
      bolditalics: "Arabic-Font.ttf",
    },
  };

  useEffect(() => {
    const getuser = async () => {
      const response = await fetch(`${route}/api/users/${userId}/`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();
      if (!response.ok) {
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }

      if (response.ok) {
        localStorage.setItem("current_user", JSON.stringify(json));
        dispatch({ type: "SetCurrentUser", data: json });
      }
    };
    getuser();
  }, []);

  const handlestate = (event) => {
    setshow(!show);
    setTarget(event.target);
  };

  const handlecollapsefun = () => {
    props.collapsefun();
    props.statefun();
  };

  const handleclick = (e) => {
    e.preventDefault();
    setdata(JSON.parse(localStorage.getItem("current_user")));
    setshowmodelupdate(true);
    setshow(!show);
  };

  return (
    <div>
      <div className="d-flex border-bottom justify-content-between header">
        {props.broken ? (
          <IconButton onClick={() => props.togglefun()}>
            <MenuIcon />
          </IconButton>
        ) : (
          <IconButton onClick={handlecollapsefun}>
            <MenuIcon />
          </IconButton>
        )}
        <div className="d-flex align-items-center  ">
          <div
            ref={ref}
            onClick={handlestate}
            className="d-flex align-items-center header_right pb-1 me-3 ps-1"
          >
            <Avatar
              src={
                current_user
                  ? current_user.profile && current_user.profile.picture
                  : ""
              }
              className="mt-1"
            />
            <h6 className="mt-2 me-5 ms-4">
              {current_user && current_user.username}
            </h6>
          </div>
        </div>

        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref}
          containerPadding={20}
        >
          <Popover id="popover-contained" className="pop_over">
            <Popover.Header className="bg-primary pop_over_header">
              <Avatar style={{ width: "50px", height: "50px" }} />
              <p className="mt-2 text-white">
                {current_user && current_user.username}
              </p>
            </Popover.Header>
            <Popover.Body>
              <div className="row">
                <div className="d-flex justify-content-between mb-2">
                  <button
                    type="button"
                    className="border"
                    onClick={handleclick}
                  >
                    Profile
                  </button>
                  <button
                    type="button"
                    className="border"
                    onClick={RemoveUser_fun}
                  >
                    Signout
                  </button>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
      {showmodelupdate && (
        <Userupdate
          show={showmodelupdate}
          onHide={() => setshowmodelupdate(false)}
          data={data}
          user={user}
          route={route}
          fun={custom_toast}
          callback={dispatch}
        />
      )}
      <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default Header;
