import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./expenses.css";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Select_field from "../alerts/select";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Avatar } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Switch from "@mui/material/Switch";

function Expensesform({
  show,
  onHide,
  user,
  route,
  callback,
  current_user,
  check,
  RemoveUser_fun,
}) {
  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const [date, setdate] = useState(curdate);
  const [remarks, setremarks] = useState("");
  const [amount, setamount] = useState("");

  const [type, settype] = useState("");
  const [alltypes, setalltypes] = useState([]);

  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    const fetchWorkouts = async () => {
      var url = `${route}/api/expense-types/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setalltypes(optimize);
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (current_user) {
      setisloading(true);
      const formData = new FormData();
      formData.append("date", date);

      formData.append("amount", amount);
      formData.append("remarks", remarks);

      formData.append("type", type.value);

      formData.append("user", current_user.id);

      const response = await fetch(`${route}/api/expenses/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
      }

      if (response.ok && check === "false") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        setdate("");
        setamount("");

        setremarks("");
        settype("");
      } else if (response.ok && check === "true") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        onHide();
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: "0.85" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <ApartmentIcon className="me-2" />
          Add Expense
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <TextField
                type="Date"
                className="form-control  mb-3"
                id="outlined-basic"
                label="date"
                value={date}
                onChange={(e) => setdate(e.target.value)}
                size="small"
                required
                autoFocus
              />
            </div>
            <div className="col-md-6">
              <Select_field
                options={alltypes}
                placeholder="Types"
                value={type}
                funct={(e) => settype(e)}
                required={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField
                type="number"
                className="form-control  mb-3"
                id="outlined-basic"
                label="Amount"
                value={amount}
                onChange={(e) => setamount(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                multiline
                className="form-control  mb-3"
                id="outlined-basic"
                label="remarks"
                value={remarks}
                onChange={(e) => setremarks(e.target.value)}
                size="small"
                required
              />
            </div>
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Expensesform;
