import React, { useState, useRef } from "react";
import "./home.css";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Outlet } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Header from "../Container/headerContainer";
import Footer from "../Components/footer";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

function Layout(props) {
  const user = props.state.setuser.user;
  const menu_status = props.state.Settablehistory.menu_status;
  const current_user = props.state.Setcurrentinfo.current_user;
  const { collapseSidebar, toggleSidebar, broken, collapsed } = useProSidebar();
  const [checkcollapse, setcheckcollapse] = useState(true);

  const handlemouseleave = () => {
    if (checkcollapse && !collapsed) {
      collapseSidebar();
    }
  };

  const handlemouseenter = () => {
    if (checkcollapse && collapsed) {
      collapseSidebar();
    }
  };

  return (
    <div id="app" className="layout d-flex" style={{ zoom: ".8" }}>
      {user && (
        <Sidebar
          breakPoint="md"
          defaultCollapsed={true}
          rootStyles={{ color: "whitesmoke" }}
          backgroundColor="#000"
        >
          <div
            style={{
              textAlign: "center",
              borderBottom: "3px solid #757575",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar className="mt-1 mb-1 ms-3 me-4" />
            <h5>{current_user && current_user.username}</h5>
          </div>
          <Menu
            onMouseEnter={handlemouseenter}
            onMouseLeave={handlemouseleave}
            style={{ height: "117vh", overflow: "auto" }}
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0 || level === 1) {
                  return {
                    color: active ? "skyblue" : undefined,
                    "&:hover": {
                      backgroundColor: "#335B8C !important",
                      color: "white !important",
                      borderRadius: "8px !important",
                      fontWeight: "bold !important",
                    },
                  };
                }
              },
            }}
          >
            <MenuItem
              active={menu_status === "dashboard"}
              icon={<DashboardIcon />}
              component={<Link to="/dashboard" />}
            >
              Dashboard
            </MenuItem>

            {current_user && (
              <>
                {current_user?.is_superuser && (
                  <MenuItem
                    active={menu_status === "user"}
                    icon={<PersonIcon />}
                    component={<Link to="/user" />}
                    rootStyles={{
                      color: "whitesmoke",
                      backgroundColor: "#000",
                    }}
                  >
                    User
                  </MenuItem>
                )}
              </>
            )}

            <MenuItem
              active={menu_status === "products"}
              icon={<DriveEtaIcon />}
              component={<Link to="/products" />}
              rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
            >
              Vehicles
            </MenuItem>

            <MenuItem
              active={menu_status === "parties"}
              icon={<PeopleIcon />}
              component={<Link to="/parties" />}
              rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
            >
              Customers
            </MenuItem>

            <MenuItem
              active={menu_status === "sale"}
              icon={<LocalAtmIcon />}
              rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              component={<Link to="/booking" />}
            >
              Booking
            </MenuItem>

            <MenuItem
              active={menu_status === "expenses"}
              icon={<ReceiptIcon />}
              rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              component={<Link to="/expenses" />}
            >
              Expenses
            </MenuItem>

            <MenuItem
              active={menu_status === "banks" || menu_status === "cashbook"}
              icon={<AccountBalanceWalletIcon />}
              rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              component={<Link to="/account_page" />}
            >
              Accounts
            </MenuItem>

            <MenuItem
              active={menu_status === "defaluter"}
              icon={<FontAwesomeIcon icon={faUserSlash} />}
              rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              component={<Link to="/defaulters" />}
            >
              Defaulters
            </MenuItem>

            <MenuItem
              active={menu_status === "Sale_Report"}
              icon={<FontAwesomeIcon icon={faChartLine} />}
              rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              component={<Link to="/Sale_Report" />}
            >
              Reports
            </MenuItem>
          </Menu>
        </Sidebar>
      )}

      <div className="content w-100 ">
        <div className="header ">
          {user && (
            <Header
              togglefun={toggleSidebar}
              collapsefun={collapseSidebar}
              broken={broken}
              statefun={() => setcheckcollapse(!checkcollapse)}
            />
          )}
        </div>

        {user ? (
          <div
            style={{
              minHeight: "108vh",
              backgroundColor: "rgb(241, 245, 245)",
            }}
          >
            <Outlet />
          </div>
        ) : (
          <div
            style={{
              minHeight: "125vh",
              backgroundColor: "rgb(241, 245, 245)",
            }}
          >
            <Outlet />
          </div>
        )}

        <div className="footer">{user && <Footer />}</div>
      </div>
    </div>
  );
}

export default Layout;
