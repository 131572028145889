import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./parties.css";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Select from "../alerts/select";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Avatar } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Switch from "@mui/material/Switch";

function Partiesform({
  show,
  onHide,
  user,
  route,
  callback,
  current_user,
  check,
  RemoveUser_fun,
}) {
  const inputFile = useRef(null);
  const [Fileurl, setFileurl] = useState([]);
  const [all_files, setall_files] = useState([]);
  const [name, setname] = useState("");
  const [contact, setcontact] = useState("");
  const [cnic, setcnic] = useState("");
  const [father_name, setfather_name] = useState("");
  const [license_no, setlicense_no] = useState("");
  const [address, setaddress] = useState("");
  const [type, settype] = useState("");
  const [status, setstatus] = useState(false);
  const [remarks, setremarks] = useState("");

  const [isloading, setisloading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (current_user) {
      setisloading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("contact", contact);
      formData.append("father_name", father_name);
      formData.append("cnic", cnic);
      formData.append("license_no", license_no);
      formData.append("address", address);
      formData.append("type", type);
      formData.append("remarks", remarks);

      all_files.forEach((item, index) => {
        formData.append(`documents[${index}]document`, item.document);
      });

      formData.append("user", current_user.id);

      const response = await fetch(`${route}/api/parties/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
      }

      if (response.ok && check === "false") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        setname("");
        setfather_name("");
        setcontact("");
        setcnic("");
        settype("");
        setaddress("");
        setremarks("");
        setlicense_no("");
        setstatus("");
        setall_files([]);

        setFileurl([]);
      } else if (response.ok && check === "true") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        onHide();
      }
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setall_files([...all_files, { document: file }]);
    }
  };

  const handleClick = (url, file) => {
    const get_urls = Fileurl.filter((item) => {
      return item !== url;
    });
    const backend_url = all_files.filter((item) => {
      return item.document.name !== file.document.name;
    });

    setFileurl(get_urls);
    setall_files(backend_url);
  };

  const handleimageclick = (item) => {
    const fileURL = URL.createObjectURL(item);
    window.open(fileURL, "_blank");
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: "0.85" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <ApartmentIcon className="me-2" />
          Add Customer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Tabs
            defaultActiveKey="Profile"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Profile" title="Profile">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    size="small"
                    required
                    autoFocus
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Type"
                    value={type}
                    onChange={(e) => settype(e.target.value)}
                    size="small"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Father Name"
                    value={father_name}
                    onChange={(e) => setfather_name(e.target.value)}
                    size="small"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="CNIC"
                    value={cnic}
                    onChange={(e) => setcnic(e.target.value)}
                    size="small"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="License No"
                    value={license_no}
                    onChange={(e) => setlicense_no(e.target.value)}
                    size="small"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Contact"
                    value={contact}
                    onChange={(e) => setcontact(e.target.value)}
                    size="small"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    multiline
                    className="form-control   mb-3"
                    id="outlined-basic"
                    label="Address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                    size="small"
                  />
                </div>
                <div className="col-md-6">
                  <label style={{ fontSize: "16px", opacity: ".6" }}>
                    Defaulter Status
                    <Switch
                      className="ms-3"
                      checked={status}
                      onChange={() => setstatus(!status)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </label>
                </div>
              </div>

              <div className="row">
                <div>
                  <TextField
                    multiline
                    className="form-control   mb-3"
                    id="outlined-basic"
                    label="Remarks"
                    value={remarks}
                    onChange={(e) => setremarks(e.target.value)}
                    size="small"
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Images" title="Documents">
              <div className="mb-3">
                <input
                  onChange={handleimageselection}
                  id="select-file"
                  type="file"
                  accept=".docx,.pdf,.txt"
                  ref={inputFile}
                  style={{ display: "none" }}
                />
                <Button onClick={onButtonClick} shadow>
                  Choose file
                </Button>
              </div>
              <div>
                {all_files.map((item, index) => {
                  return (
                    <span className="mb-2 d-flex " key={index}>
                      <span
                        className="file p-2"
                        onClick={() => {
                          handleimageclick(item.document);
                        }}
                      >
                        {item.document.name}
                      </span>
                      <Badge
                        color="error"
                        key={index}
                        overlap="circular"
                        badgeContent="X"
                        onClick={() => {
                          handleClick(item, all_files[index]);
                        }}
                        className="me-3 badgee"
                      ></Badge>
                    </span>
                  );
                })}
              </div>
            </Tab>
          </Tabs>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Partiesform;
