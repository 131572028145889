import { connect } from "react-redux";
import Sale from "../Components/booking/booking";

import {
  Settablehistory,
  Setproducthistory,
  removeuser,
} from "../Service/Actions/action";

const dispatchpart = (dispatch) => ({
  Settable_history: (data) => dispatch(Settablehistory(data)),
  Setproduct_history: (data) => dispatch(Setproducthistory(data)),
  RemoveUser: () => dispatch(removeuser()),
});

const getpart = (state) => ({
  state,
});

export default connect(getpart, dispatchpart)(Sale);
