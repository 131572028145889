import React, { useState, useEffect } from "react";
import { IconButton, Avatar } from "@material-ui/core";
import cellEditFactory from "react-bootstrap-table2-editor";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import Alert_before_delete from "../../Container/alertContainer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./expenses.css";
import SaveIcon from "@material-ui/icons/Save";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentIcon from "@material-ui/icons/Payment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

function Typeform({ show, onHide, user, route, current_user }) {
  const [type, settype] = useState("");
  const [data, setdata] = useState([]);
  const { SearchBar } = Search;

  const { ExportCSVButton } = CSVExport;

  useEffect(() => {
    const fetchWorkouts = async () => {
      var url = `${route}/api/expense-types/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setdata(json);
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(`${route}/api/expense-types/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access}`,
      },
      body: JSON.stringify({ name: type, user: current_user.id }),
    });
    const json = await response.json();

    if (response.ok) {
      setdata([...data, json]);
      settype("");
    }
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const Action = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex ">
        <IconButton
          className="border border-danger rounded me-2"
          onClick={async () => {
            const response = fetch(`${route}/api/expense-types/${row.id}/`, {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${user.access}`,
              },
            });

            const optimize = data.filter((item) => {
              return row.id !== item.id;
            });
            setdata(optimize);
          }}
        >
          <DeleteRoundedIcon className="m-1" color="error" fontSize="small" />
        </IconButton>
      </span>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "name",
      text: "Expense Types",
      sort: true,
      headerFormatter: headerstyle,
    },

    {
      dataField: "action",
      text: "Action",
      formatter: Action,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ms-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: 30,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const rowstyle = { height: "10px" };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <PaymentIcon className="me-2" />
          Add Expense Type
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center mt-2">
            <TextField
              className="me-2"
              id="outlined-basic"
              label="Expense Type"
              value={type}
              onChange={(e) => {
                settype(e.target.value);
              }}
              size="small"
            />
            <Button type="submit" variant="outline-primary">
              <SaveIcon /> Save
            </Button>
          </div>
        </form>
        <hr />

        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <div className="d-flex justify-content-between ">
                <ExportCSVButton
                  {...props.csvProps}
                  className="csvbutton border mt-4 bg-secondary text-light"
                >
                  Export CSV
                </ExportCSVButton>
                <SearchBar {...props.searchProps} />
              </div>

              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                rowStyle={rowstyle}
                striped
                bootstrap4
                condensed
                rowClasses="custom-row-class"
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>

        <ToastContainer autoClose={2000} hideProgressBar={true} theme="dark" />
      </Modal.Body>
    </Modal>
  );
}

export default Typeform;
