import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import "./booking.css";
import Button from "react-bootstrap/Button";
import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Select from "react-select";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@mui/material/TextField";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ToastContainer } from "react-toastify";
import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Red_toast from "../alerts/red_toast";
import Paymentmodel from "./paymentmodel";
import Badge from "@mui/material/Badge";

function Sale_Edit(props) {
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const setActiveTab = props.setActiveTab;
  const current_user = props.state.Setcurrentinfo.current_user;
  const check_status = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const current_data = props.state.Setsavedata.save_data;
  console.log(current_data);
  const table_data = props.state.Setproducthistory.product_history;
  const settable_data = props.Setproduct_history;
  const RemoveUser_fun = props.RemoveUser;

  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const inputFile = useRef(null);
  const cursorposition = useRef(null);
  const [isloading, setisloading] = useState(false);

  const [print, setprint] = useState(false);
  const [getlatest_invoice, setgetlatest_invoice] = useState(false);
  const [id, setid] = useState("");
  const [invoice, setinvoice] = useState("");
  const [date, setdate] = useState();
  const [to_date, setto_date] = useState();
  const [from_date, setfrom_date] = useState();
  const [payment_counter, setpayment_counter] = useState(1);
  const [showpayment_model, setshowpayment_model] = useState(false);
  const [remaining, setremaining] = useState("");
  const [all_customers, setall_customers] = useState([]);
  const [customer, setcustomer] = useState("");

  const [all_product_option, setall_product_option] = useState([]);
  const [product, setproduct] = useState("");

  const [booking_type, setbooking_type] = useState("");
  const [all_booking_types, setall_booking_types] = useState([
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Custom", label: "Custom" },
  ]);
  const [reference_person, setreference_person] = useState("");
  const [departure_reading, setdeparture_reading] = useState("");
  const [arrival_reading, setarrival_reading] = useState("");
  const [check, setcheck] = useState(true);
  const [destination, setdestination] = useState("");
  const [total, settotal] = useState(0);
  const [amount_received, setamount_received] = useState(0);
  const [notes, setnotes] = useState("");
  const [Fileurl, setFileurl] = useState([]);
  const [all_files, setall_files] = useState([]);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "sale" });

    setid(current_data.id);
    setdate(current_data.date);
    setinvoice(current_data.booking_number);
    setto_date(current_data.from_date);
    setfrom_date(current_data.to_date);
    setbooking_type({
      value: current_data.booking_type,
      label: current_data.booking_type,
    });
    setproduct({
      value: current_data.product_info.id,
      label: current_data.product_info.name,
    });

    setcustomer({
      value: current_data.customer_info.id,
      label: current_data.customer_info.name,
    });
    setreference_person({
      value: current_data.reference_person_info.id,
      label: current_data.reference_person_info.name,
    });

    setdeparture_reading(current_data.departure_reading);
    setarrival_reading(current_data.arrival_reading);
    setdestination(current_data.destination);

    setnotes(current_data.remarks);
    settotal(current_data.total);
    var count = 0;
    const paymentarray = current_data.payments.map((item) => {
      count += 1;
      return {
        prod_id: count,
        ...item,
      };
    });
    setpayment_counter(count);
    settable_data({ type: "Set_product_history", data: paymentarray });
    setall_files(
      current_data.images.map((item) => {
        const splitlist = item.picture.split("/");
        return {
          id: item.id,
          picture: { name: splitlist[splitlist.length - 1], url: item.picture },
        };
      })
    );
  }, []);

  useEffect(() => {
    const fetchcustomer = async () => {
      const response = await fetch(
        `${route}/api/parties/?user_id=${current_user.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_customers(optimize);
      }
      if (!response.ok) {
        went_wrong_toast();
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }
    };

    fetchcustomer();
  }, []);

  useEffect(() => {
    if (table_data.length > 0) {
      setprint(true);
    } else {
      setprint(false);
    }
  }, [table_data]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(
        `${route}/api/Vehicles/?user_id=${current_user.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const pro = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_product_option(pro);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };

    if (user) {
      fetchProducts();
    }
  }, []);

  useEffect(() => {
    var total_total = 0;
    table_data.forEach((item) => {
      total_total += Number(item.amount);
    });
    if (check) {
      setremaining(current_data.total - total_total);
      setcheck(false);
    } else {
      setremaining(total - total_total);
    }

    setamount_received(total_total);
  }, [table_data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setisloading(true);
    const datadetail = table_data.map((item) => {
      delete item["prod_id"];
      return item;
    });

    const formData = new FormData();

    formData.append("remarks", notes);
    formData.append("date", date);

    formData.append("booking_number", invoice);
    formData.append("from_date", from_date);
    formData.append("to_date", to_date);

    formData.append("destination", destination);

    formData.append("customer", customer.value);

    formData.append("reference_person", reference_person.value);
    formData.append("total", total);
    formData.append("amount_recieved", amount_received);

    formData.append("booking_type", booking_type.value);
    formData.append("departure_reading", departure_reading);
    formData.append("product", product.value);
    formData.append("arrival_reading", arrival_reading);
    all_files.forEach((item, index) => {
      if (item.id) {
        formData.append(`images[${index}]id`, item.id);
      } else {
        formData.append(`images[${index}]picture`, item.picture);
      }
    });
    datadetail.forEach((item, index) => {
      if (item.id) {
        formData.append(`payments[${index}]`, JSON.stringify(id));
      } else {
        formData.append(`payments[${index}]`, JSON.stringify(item));
      }
    });

    const response = await fetch(`${route}/api/bookings/${id}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formData,
    });
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      Red_toast(json.error);
    }

    if (response.ok) {
      setisloading(false);
      success_toast();
      settable_data({ type: "Set_product_history", data: [] });
      setinvoice("");
      setproduct("");
      setamount_received(0);

      setdestination("");
      setarrival_reading("");
      setdeparture_reading("");
      setdate(curdate);
      setfrom_date(curdate);
      setto_date(curdate);
      setnotes("");
      settotal(0);
      setprint(false);
      setcustomer("");
      setreference_person("");
      setgetlatest_invoice(!getlatest_invoice);
      setpayment_counter(1);
      setall_files([]);
      setbooking_type("");
      setActiveTab("sale_history");
    }
  };

  const handlePrint = async () => {
    if (!isloading) {
      const datadetail = table_data.map((item) => {
        delete item["prod_id"];
        return item;
      });

      const formData = new FormData();

      formData.append("remarks", notes);
      formData.append("date", date);

      formData.append("booking_number", invoice);
      formData.append("from_date", from_date);
      formData.append("to_date", to_date);

      formData.append("destination", destination);

      formData.append("customer", customer.value);

      formData.append("reference_person", reference_person.value);
      formData.append("total", total);
      formData.append("amount_recieved", amount_received);

      formData.append("booking_type", booking_type.value);
      formData.append("departure_reading", departure_reading);
      formData.append("product", product.value);
      formData.append("arrival_reading", arrival_reading);
      all_files.forEach((item, index) => {
        if (item.id) {
          formData.append(`images[${index}]id`, item.id);
        } else {
          formData.append(`images[${index}]picture`, item.picture);
        }
      });
      datadetail.forEach((item, index) => {
        if (item.id) {
          formData.append(`payments[${index}]`, JSON.stringify(id));
        } else {
          formData.append(`payments[${index}]`, JSON.stringify(item));
        }
      });

      const response = await fetch(`${route}/api/bookings/${id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        Red_toast(json.error);
      }

      if (response.ok) {
        localStorage.setItem("data", JSON.stringify(json));
        settable_data({ type: "Set_product_history", data: [] });
        setgetlatest_invoice(!getlatest_invoice);
        setinvoice("");
        setnotes("");
        settotal(0);
        setproduct("");
        setamount_received(0);
        setcustomer("");

        setdestination("");
        setall_files([]);
        setdeparture_reading("");
        setarrival_reading("");
        setdate(curdate);
        setfrom_date(curdate);
        setto_date(curdate);
        setprint(false);
        setpayment_counter(1);
        setcustomer("");
        setreference_person("");
        setbooking_type("");
        window.open("/invoice/sales", "_blank");
        setActiveTab("sale_history");
      }
    }
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="action text-center">
        <IconButton
          onClick={() => {
            settable_data({
              type: "Delete_product_history",
              data: row,
            });
          }}
        >
          <DeleteRoundedIcon color="error" fontSize="medium" />
        </IconButton>
      </div>
    );
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const columns = [
    {
      dataField: "prod_id",
      text: "#",
      headerFormatter: headerstyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      headerFormatter: headerstyle,
    },
    { dataField: "amount", text: "Amount", headerFormatter: headerstyle },
    { dataField: "remarks", text: "Remarks", headerFormatter: headerstyle },
    {
      dataField: "Edit",
      text: "Action",
      formatter: linkFollow,
      headerFormatter: headerstyle,
    },
  ];

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handlepictureselection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setall_files([...all_files, { picture: file }]);
    }

    // const reader = new FileReader();
    // reader.onload = () => {
    //   setFileurl([...Fileurl, reader.result]);
    // };
    // reader.readAsDataURL(file);
  };

  const handleClick = (url, file) => {
    const get_urls = Fileurl.filter((item) => {
      return item !== url;
    });
    const backend_url = all_files.filter((item) => {
      return item.picture.name !== file.picture.name;
    });

    setFileurl(get_urls);
    setall_files(backend_url);
  };

  const handleimageclick = (item) => {
    if (item instanceof File) {
      const fileURL = URL.createObjectURL(item);
      window.open(fileURL, "_blank");
    } else {
      window.open(item.url, "_blank");
    }
  };

  return (
    <div className="p-3 ">
      <ToolkitProvider
        keyField="prod_id"
        data={table_data}
        columns={columns}
        search
        exportCSV
      >
        {(props) => (
          <div className="card">
            <form onSubmit={handleSubmit}>
              <div className="card-header  d-flex justify-content-end">
                {print ? (
                  <Button type="submit" variant="outline-primary">
                    {isloading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    <SaveIcon /> Save
                  </Button>
                ) : (
                  <Button variant="outline-primary" disabled>
                    <SaveIcon /> Save
                  </Button>
                )}

                {print ? (
                  <Button
                    className="ms-2"
                    variant="outline-success"
                    onClick={handlePrint}
                  >
                    <PrintRoundedIcon /> Print
                  </Button>
                ) : (
                  <Button className="ms-2" variant="outline-success" disabled>
                    <PrintRoundedIcon /> Print
                  </Button>
                )}
              </div>

              <div className="card-body ">
                <div className="d-md-flex align-items-start mt-1">
                  <div className="col-sm-5 mt-1 pe-md-5">
                    <div>
                      <div className="d-flex justify-content-between">
                        <div className="col-6  me-2">
                          <TextField
                            className="form-control   mb-4"
                            label="Booking No."
                            value={invoice}
                            size="small"
                            required
                          />
                        </div>

                        <div className=" col-6 ">
                          <TextField
                            type="date"
                            className="form-control   mb-4"
                            label="Date"
                            InputLabelProps={{ shrink: true }}
                            value={date}
                            onChange={(e) => {
                              setdate(e.target.value);
                            }}
                            size="small"
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="col-6  me-2">
                          <TextField
                            type="date"
                            className="form-control   mb-4"
                            InputLabelProps={{ shrink: true }}
                            label="From"
                            value={from_date}
                            onChange={(e) => {
                              setfrom_date(e.target.value);
                            }}
                            size="small"
                            ref={cursorposition}
                            required
                            autoFocus
                          />
                        </div>

                        <div className="col-6 ">
                          <TextField
                            type="date"
                            className="form-control   mb-4"
                            label="To"
                            InputLabelProps={{ shrink: true }}
                            value={to_date}
                            onChange={(e) => {
                              setto_date(e.target.value);
                            }}
                            size="small"
                            required
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="col-6 me-2">
                          <Select
                            className={
                              booking_type
                                ? "form-control selector booking_type mb-4"
                                : "form-control selector mb-4"
                            }
                            styles={selectStyles}
                            options={all_booking_types}
                            placeholder="Booking Type"
                            value={booking_type}
                            onChange={(e) => {
                              setbooking_type(e);
                            }}
                            required
                          ></Select>
                        </div>
                        <div className="col-6  ">
                          <Select
                            className={
                              product
                                ? "form-control selector product mb-4"
                                : "form-control selector mb-4"
                            }
                            styles={selectStyles}
                            options={all_product_option}
                            placeholder="Vehicles"
                            value={product}
                            onChange={(e) => {
                              setproduct(e);
                            }}
                            required
                          ></Select>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="col-6 me-2">
                          <Select
                            className={
                              customer
                                ? "form-control selector customer mb-4"
                                : "form-control selector mb-4"
                            }
                            styles={selectStyles}
                            options={all_customers}
                            placeholder="Customer"
                            value={customer}
                            onChange={(e) => {
                              setcustomer(e);
                            }}
                            required
                          ></Select>
                        </div>

                        <div className="col-6">
                          <Select
                            className={
                              reference_person
                                ? "form-control selector reference_person mb-4"
                                : "form-control selector mb-4"
                            }
                            styles={selectStyles}
                            options={all_customers}
                            placeholder="Reference Person"
                            value={reference_person}
                            onChange={(e) => {
                              setreference_person(e);
                            }}
                            required
                          ></Select>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="col-6 me-2">
                          <TextField
                            className="form-control   mb-4"
                            label="Departure Reading"
                            value={departure_reading}
                            onChange={(e) => {
                              setdeparture_reading(e.target.value);
                            }}
                            size="small"
                            required
                          />
                        </div>

                        <div className="col-6">
                          <TextField
                            className="form-control   mb-4"
                            id="outlined-basic"
                            label="Arrival Reading"
                            value={arrival_reading}
                            onChange={(e) => {
                              setarrival_reading(e.target.value);
                            }}
                            size="small"
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="col-6 me-2">
                          <TextField
                            className="form-control   mb-4"
                            id="outlined-basic"
                            label="Destination"
                            value={destination}
                            onChange={(e) => {
                              setdestination(e.target.value);
                            }}
                            size="small"
                            required
                          />
                        </div>

                        <div className="col-6">
                          <TextField
                            className="form-control   mb-4"
                            id="outlined-basic"
                            label="Remaining"
                            value={remaining}
                            size="small"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="col-6 me-2">
                          <TextField
                            className="form-control   mb-4"
                            label="Total"
                            value={total}
                            onChange={(e) => {
                              settotal(e.target.value);
                              setremaining(e.target.value - amount_received);
                            }}
                            size="small"
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            className="form-control   mb-4"
                            id="outlined-basic"
                            label="Receive Amount"
                            value={amount_received}
                            size="small"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row ps-2 pe-2 mb-2">
                        <TextField
                          multiline
                          className="form-control ms-1 "
                          label="Notes"
                          value={notes}
                          onChange={(e) => {
                            setnotes(e.target.value);
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-7  ">
                    <div className="d-flex justify-content-between align-items-end mb-2">
                      <h6 className="mb-0">Payments</h6>
                      <Button
                        variant="outline-secondary "
                        className="pt-1 pb-1"
                        onClick={() => {
                          setshowpayment_model(!showpayment_model);
                        }}
                      >
                        <AddIcon /> Add
                      </Button>
                    </div>

                    <div style={{ height: "36vh", border: "1px solid gray" }}>
                      <div style={{ height: "36vh", overflow: "auto" }}>
                        <BootstrapTable
                          {...props.baseProps}
                          bordered={false}
                          bootstrap4
                          condensed
                          rowClasses="custom_row_class"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="d-flex justify-content-between align-items-end mb-2">
                        <h6 className="mb-0">Media</h6>
                        <div>
                          <input
                            onChange={handlepictureselection}
                            id="select-file"
                            type="file"
                            accept=".docx,.pdf,.txt,.png,.jpg,.jpeg"
                            ref={inputFile}
                            style={{ display: "none" }}
                          />
                          <Button
                            onClick={onButtonClick}
                            variant="outline-secondary"
                            shadow
                          >
                            Choose file
                          </Button>
                        </div>
                      </div>
                      <div
                        className="row m-1"
                        style={{ border: "1px solid gray" }}
                      >
                        {all_files.map((item, index) => {
                          return (
                            <span
                              className="mb-2 d-flex p-2"
                              style={{ width: "fit-content" }}
                              onClick={() => {
                                handleimageclick(item.picture);
                              }}
                              key={index}
                            >
                              <span className="file p-2">
                                {item.picture.name}
                              </span>
                              <Badge
                                color="error"
                                key={index}
                                overlap="circular"
                                badgeContent="X"
                                onClick={() => {
                                  handleClick(item, all_files[index]);
                                }}
                                className="me-3 badgee"
                              ></Badge>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ToastContainer
                autoClose={1000}
                hideProgressBar={true}
                theme="dark"
              />
            </form>
          </div>
        )}
      </ToolkitProvider>

      {showpayment_model && (
        <Paymentmodel
          show={showpayment_model}
          onHide={() => setshowpayment_model(false)}
          payment_data={table_data}
          setpayment_data={settable_data}
          payment_counter={payment_counter}
          setpayment_counter={() => setpayment_counter(payment_counter + 1)}
        />
      )}
    </div>
  );
}

export default Sale_Edit;
