import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import Layout from "../Container/homeContainer";
import User from "../Container/userContainer";
import Vehicle from "../Container/vehicleContainer";
import Parties from "../Container/partiesContainer";
import Account_page from "./accountpage/accountPage";
import Sale_page from "./bookingpage/bookingpage";
import Dashboard from "../Container/dashboardContainer";
import Expenses from "../Container/expenseConatiner";
import Defaulters from "../Container/defaulterContainer";
import Sale_Report from "../Container/salerportContainer";
import Login from "../Container/loginContainer";
import Sale_invoice from "../Container/invoiceContainer";

function Routing(props) {
  const user = props.state.setuser.user;
  return (
    <BrowserRouter>
      <ProSidebarProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={!user ? <Login /> : <Navigate to={"/dashboard"} />}
            ></Route>
            <Route
              path="/dashboard"
              element={user ? <Dashboard /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/user"
              element={user ? <User /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/expenses"
              element={user ? <Expenses /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/products"
              element={user ? <Vehicle /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/parties"
              element={user ? <Parties /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/account_page"
              element={user ? <Account_page /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/booking"
              element={user ? <Sale_page /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/sale_report"
              element={user ? <Sale_Report /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/defaulters"
              element={user ? <Defaulters /> : <Navigate to={"/"} />}
            ></Route>
          </Route>
          <Route
            path="/invoice/:name"
            element={user && <Sale_invoice />}
          ></Route>
        </Routes>
      </ProSidebarProvider>
    </BrowserRouter>
  );
}

export default Routing;
