import React, { useState, useEffect, useRef } from "react";
import "./vehicle.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Avatar } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import went_wrong_toast from "../alerts/went_wrong_toast";
import Update_button from "../buttons/update_button";
import { ToastContainer } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Vehicleupdate({
  show,
  onHide,
  data,
  user,
  route,
  fun,
  callback,
  current_user,
  RemoveUser_fun,
}) {
  const inputFile = useRef(null);

  const [Fileurl, setFileurl] = useState(
    data.images.map((item) => {
      return { name: item.picture, url: item.picture };
    })
  );

  const [name, setname] = useState(data.name);
  const [status, setstatus] = useState(data.status);
  const [make, setmake] = useState(data.make);
  const [model, setmodel] = useState(data.model);
  const [registration_no, setregistration_no] = useState(data.registration_no);
  const [type, settype] = useState(data.type);
  const [color, setcolor] = useState(data.color);
  const [isloading, setisloading] = useState(false);

  const [all_images, setall_images] = useState(data.images);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisloading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("status", status);
    formData.append("make", make);
    formData.append("model", model);
    formData.append("type", type);
    formData.append("color", color);
    formData.append("registration_no", registration_no);
    all_images.forEach((item, index) => {
      if (item.id) {
        formData.append(`images[${index}]id`, item.id);
      } else {
        formData.append(`images[${index}]picture`, item.picture);
      }
    });

    const response = await fetch(`${route}/api/Vehicles/${data.id}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formData,
    });
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      went_wrong_toast();
    }

    if (response.ok) {
      setisloading(false);
      callback({ type: "Update_table_history", data: json });
      onHide();
      fun("Update");
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];

    if (file) {
      setall_images([...all_images, { picture: file }]);
      const reader = new FileReader();
      reader.onload = () => {
        setFileurl([...Fileurl, { name: reader.result, url: file }]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = (url, file) => {
    const get_urls = Fileurl.filter((item) => {
      return item.name !== url;
    });
    const backend_url = all_images.filter((item) => {
      if (item.id) {
        return item.picture !== url;
      } else {
        return item.picture.name !== file.picture.name;
      }
    });
    setFileurl(get_urls);
    setall_images(backend_url);
  };

  const handleimageclick = (item) => {
    if (item instanceof File) {
      const fileURL = URL.createObjectURL(item);
      window.open(fileURL, "_blank");
    } else {
      window.open(item, "_blank");
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: "0.85" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <ApartmentIcon className="me-2" />
          Edit Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Tabs
            defaultActiveKey="Profile"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Profile" title="Profile">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    size="small"
                    autoFocus
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Model"
                    value={model}
                    onChange={(e) => setmodel(e.target.value)}
                    size="small"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Type"
                    value={type}
                    onChange={(e) => settype(e.target.value)}
                    size="small"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Color"
                    value={color}
                    onChange={(e) => setcolor(e.target.value)}
                    size="small"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Make"
                    value={make}
                    onChange={(e) => setmake(e.target.value)}
                    size="small"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label="Registration No"
                    value={registration_no}
                    onChange={(e) => setregistration_no(e.target.value)}
                    size="small"
                    required
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Images" title="Images">
              <div className="mb-3">
                <input
                  onChange={handleimageselection}
                  id="select-file"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  ref={inputFile}
                  style={{ display: "none" }}
                />
                <Button onClick={onButtonClick} shadow>
                  Choose file
                </Button>
              </div>
              <div className="d-flex">
                {Fileurl.map((item, index) => {
                  return (
                    <div className="mb-2 me-3 d-flex claas-images" key={index}>
                      <Avatar
                        src={item.name}
                        className="avatar"
                        style={{ width: "130px", height: "130px" }}
                        alt="image"
                        onClick={() => {
                          handleimageclick(item.url);
                        }}
                      />
                      <Badge
                        color="error"
                        overlap="circular"
                        className="badgeepic"
                        badgeContent="X"
                        onClick={() => {
                          handleClick(item.name, all_images[index]);
                        }}
                      ></Badge>
                    </div>
                  );
                })}
              </div>
            </Tab>
          </Tabs>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Update_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Vehicleupdate;
