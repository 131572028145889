import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";

import Routing from './Container/routingContainer'
import React from 'react'

function App() {
  
  return (
    
      <Routing/>

    
  );
}

export default App;
