import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./cashbook.css";
import Select_field from "../alerts/select";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@material-ui/core";
import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";

function Cashbookform({ show, onHide, user, route, callback, current_user }) {
  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const [date, setdate] = useState(curdate);
  const [remarks, setremarks] = useState("");
  const [isloading, setisloading] = useState(false);
  const [bank, setbank] = useState("");
  const [allbank, setallbank] = useState([]);

  const [type, settype] = useState("");
  const [alltype, setalltype] = useState([
    { value: "cash_in", label: "Cash In" },
    { value: "cash_out", label: "Cash Out" },
  ]);
  const [amount, setamount] = useState("");

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch(
        `${route}/api/banks/?user_id=${current_user.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setallbank(optimize);
      }
      if (!response.ok) {
        setisloading(false);
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (current_user) {
      setisloading(true);
      const formData = new FormData();

      formData.append("date", date);
      formData.append("remarks", remarks);
      formData.append("type", type.value);
      formData.append("amount", amount);
      formData.append("bank", bank.value);

      formData.append("user", current_user.id);

      const response = await fetch(`${route}/api/cashbook/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
      }

      if (response.ok) {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        setdate(curdate);
        setremarks("");
        settype("");
        setamount("");
        setbank("");
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: "0.85" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          classdate="d-flex align-items-md-center"
        >
          <FontAwesomeIcon classdate="me-2" icon={faUserPlus} /> Add Cashbook
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <TextField
                type="Date"
                className="form-control  mb-3"
                id="outlined-basic"
                label="Date"
                value={date}
                onChange={(e) => setdate(e.target.value)}
                size="small"
                required
                autoFocus
              />
            </div>
            <div className="col-md-6">
              <Select_field
                options={allbank}
                placeholder="Banks"
                value={bank}
                funct={(e) => setbank(e)}
                required={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField
                type="number"
                className="form-control  mb-3"
                id="outlined-basic"
                label="Amount"
                value={amount}
                onChange={(e) => setamount(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="col-md-6">
              <Select_field
                options={alltype}
                placeholder="Type"
                value={type}
                funct={(e) => settype(e)}
                required={true}
              />
            </div>
          </div>

          <div>
            <TextField
              multiline
              className="form-control"
              id="outlined-basic"
              label="Remarks"
              value={remarks}
              onChange={(e) => setremarks(e.target.value)}
              size="small"
            />
          </div>

          <hr></hr>
          <div classdate=" d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Cashbookform;
