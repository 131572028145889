import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./booking.css";
import Button from "react-bootstrap/Button";
import { ToastContainer } from "react-toastify";
import TextField from "@mui/material/TextField";
function Paymentmodel({
  show,
  onHide,
  payment_data,
  setpayment_data,
  payment_counter,
  setpayment_counter,
}) {
  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const [date, setdate] = useState(curdate);
  const [amount, setamount] = useState(0);
  const [remarks, setremarks] = useState("");

  const handleClick = (e) => {
    e.preventDefault();

    setpayment_data({
      type: "Create_product_history",
      data: {
        prod_id: payment_counter,
        date: date,
        amount: amount,
        remarks: remarks,
      },
    });
    setamount(0);
    setremarks("");
    setpayment_counter();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          Add Payment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleClick}>
          <div className="row">
            <div className="col-md-6 ">
              <TextField
                type="Date"
                className="form-control   mb-3"
                id="outlined-basic"
                label="Date"
                value={date}
                onChange={(e) => {
                  setdate(e.target.value);
                }}
                size="small"
              />
            </div>
            <div className="col-md-6 ">
              <TextField
                type="number"
                className="form-control   mb-3"
                id="outlined-basic"
                label="Amount"
                value={amount}
                onChange={(e) => {
                  setamount(e.target.value);
                }}
                size="small"
                required
                autoFocus
              />
            </div>
          </div>
          <div>
            <TextField
              multiline
              className="form-control   mb-3"
              id="outlined-basic"
              label="Remarks"
              value={remarks}
              onChange={(e) => {
                setremarks(e.target.value);
              }}
              size="small"
            />
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Button type="submit" variant="outline-primary">
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Paymentmodel;
