import React, { useState, useEffect, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import "./report.css";
import { useReactToPrint } from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer } from "react-toastify";
import custom_toast from "../alerts/custom_toast";
import went_wrong_toast from "../alerts/went_wrong_toast";
import Spinner from "react-bootstrap/Spinner";
import Select from "../alerts/select";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import TextField from "@mui/material/TextField";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";

export default function Sale_Report(props) {
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const RemoveUser_fun = props.RemoveUser;
  const current_user = props.state.Setcurrentinfo.current_user;
  const [show_company, setshow_company] = useState(false);
  const dispatch = props.Settable_history;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [details, setdetails] = useState([]);

  const [start_date, setstart_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [end_date, setend_date] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [date_range, setdate_range] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      showDateDisplay: "false",
    },
  ]);

  const [isloading, setisloading] = useState(false);
  const [showreport, setshowreport] = useState(false);
  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "Sale_Report" });
  }, []);

  const headerstyle = (column, colIndex) => {
    return <div>{column.text}</div>;
  };

  const columns = [
    {
      dataField: "id_data",
      text: "#",
      headerFormatter: headerstyle,
      formatter: (cell, row, rowIndex) => {
        if (details.length - 1 !== rowIndex) {
          return rowIndex + 1;
        }
      },
    },
    { dataField: "date", text: "Date", headerFormatter: headerstyle },
    {
      dataField: "booking_number",
      text: "Booking No.",
      headerFormatter: headerstyle,
    },
    {
      dataField: "customer_name",
      text: "Customer",
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "vehicle",
      text: "Vehicle",
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      headerFormatter: headerstyle,
    },
  ];

  const rowstyle = { height: "10px" };

  const handleSelect = (item) => {
    const get_date = item.selection;
    setdate_range([item.selection]);
    setstart_date(get_date.startDate.toISOString().substring(0, 10));
    setend_date(get_date.endDate.toISOString().substring(0, 10));
    if (
      get_date.startDate.toISOString().substring(0, 10) !==
      get_date.endDate.toISOString().substring(0, 10)
    ) {
      setshow(!show);
    }
  };

  const handleselectiochange = (e) => {
    setshow(!show);
    setTarget(e.target);
  };

  const componentRef = useRef();

  useEffect(() => {
    if (show_company) {
      handleprint();
    }
  }, [show_company]);

  const print = (e) => {
    e.preventDefault();
    setshow_company(true);
  };

  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print_class",
    pageStyle: "@page { size: A4 landscape; }",
    onAfterPrint: () => {
      setshow_company(false);
    },
  });

  const handlegenerate = async (e) => {
    e.preventDefault();
    setisloading(true);
    setshowreport(true);
    var url = `${route}/api/sale-report/?user_id=${current_user.id}&start_date=${start_date}&end_date=${end_date}`;

    const response = await fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.access}` },
    });
    const json = await response.json();

    if (response.ok) {
      var total = 0;
      const optimize = json.map((item) => {
        total += item.amount;
      });
      json.push({ amount: `Total : ${total}` });
      setdetails(json);
      setisloading(false);
    }
    if (!response.ok) {
      setisloading(false);
      went_wrong_toast();
      if (json.code === "token_not_valid") {
        RemoveUser_fun();
      }
    }
  };

  const rowClasses = (row, rowIndex) => {
    let classes = null;

    if (rowIndex === details.length - 1) {
      classes = "last_row";
    }

    return classes;
  };

  return (
    <div className="p-3">
      <h1 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "normal" }}>
        Payment Report
      </h1>
      <div className="card">
        <div className="card-body pt-0">
          <ToolkitProvider
            keyField="id"
            data={details}
            columns={columns}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <div className="col-sm-10 d-flex justify-content-start align-items-start mt-3">
                  <div className="col-sm-3  me-3">
                    {date_range[0].endDate.getFullYear() -
                      date_range[0].startDate.getFullYear() ===
                    30 ? (
                      <TextField
                        ref={ref}
                        type="button"
                        className="form-control   mb-3"
                        label="Date"
                        value="From Start"
                        onClick={handleselectiochange}
                        size="small"
                      />
                    ) : (
                      <TextField
                        ref={ref}
                        type="button"
                        className="form-control  mb-3 "
                        label="Date"
                        value={`${date_range[0].startDate
                          .toLocaleString("en-GB")
                          .substring(0, 10)} - ${date_range[0].endDate
                          .toLocaleString("en-GB")
                          .substring(0, 10)}`}
                        onClick={handleselectiochange}
                        size="small"
                      />
                    )}
                    <Overlay
                      show={show}
                      target={target}
                      placement="bottom-start"
                      container={ref}
                    >
                      <Popover id="popover-contained" className="pop_over">
                        <Popover.Body>
                          <div>
                            <DateRangePicker
                              onChange={handleSelect}
                              showSelectionPreview={true}
                              showCalendarPreview={false}
                              dragSelectionEnabled={true}
                              moveRangeOnFirstSelection={false}
                              months={2}
                              ranges={date_range}
                              direction="horizontal"
                              preventSnapRefocus={true}
                              calendarFocus="backwards"
                              staticRanges={[
                                ...defaultStaticRanges,
                                {
                                  label: "Last Year",
                                  range: () => ({
                                    startDate: startOfYear(
                                      addYears(new Date(), -1)
                                    ),
                                    endDate: endOfYear(
                                      addYears(new Date(), -1)
                                    ),
                                  }),
                                  isSelected(range) {
                                    const definedRange = this.range();
                                    return (
                                      isSameDay(
                                        range.startDate,
                                        definedRange.startDate
                                      ) &&
                                      isSameDay(
                                        range.endDate,
                                        definedRange.endDate
                                      )
                                    );
                                  },
                                },
                                {
                                  label: "This Year",
                                  range: () => ({
                                    startDate: startOfYear(new Date()),
                                    endDate: endOfDay(new Date()),
                                  }),
                                  isSelected(range) {
                                    const definedRange = this.range();
                                    return (
                                      isSameDay(
                                        range.startDate,
                                        definedRange.startDate
                                      ) &&
                                      isSameDay(
                                        range.endDate,
                                        definedRange.endDate
                                      )
                                    );
                                  },
                                },
                                {
                                  label: "From Start",
                                  range: () => ({
                                    startDate: startOfYear(
                                      addYears(new Date(), -30)
                                    ),
                                    endDate: endOfDay(new Date()),
                                  }),
                                  isSelected(range) {
                                    const definedRange = this.range();
                                    return (
                                      isSameDay(
                                        range.startDate,
                                        definedRange.startDate
                                      ) &&
                                      isSameDay(
                                        range.endDate,
                                        definedRange.endDate
                                      )
                                    );
                                  },
                                },
                              ]}
                            />
                          </div>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </div>

                  <Button
                    type="button"
                    className="mb-3 me-3"
                    variant="outline-success"
                    size="small"
                    onClick={handlegenerate}
                  >
                    Generate
                  </Button>
                </div>
                {isloading && (
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}

                {showreport && (
                  <>
                    <div className="d-md-flex justify-content-between align-items-center mt-2">
                      <div>
                        <ExportCSVButton
                          {...props.csvProps}
                          className="csvbutton me-3 border bg-secondary text-light"
                        >
                          Export CSV
                        </ExportCSVButton>

                        <Button
                          type="button"
                          className="mt-3 mb-3 me-3"
                          variant="outline-success"
                          size="small"
                          onClick={print}
                        >
                          Print
                        </Button>
                      </div>
                      <SearchBar {...props.searchProps} />
                    </div>

                    <hr />
                    <div ref={componentRef}>
                      {show_company && (
                        <div>
                          <h3 className="text-center">Payments Report</h3>

                          <h6 className="text-center">
                            <strong className="me-3">Date:</strong>
                            {date_range[0].startDate
                              .toLocaleString("en-GB")
                              .substring(0, 10)}
                            -
                            {date_range[0].endDate
                              .toLocaleString("en-GB")
                              .substring(0, 10)}
                            <br></br>
                          </h6>
                        </div>
                      )}
                      <div style={{ zoom: ".8" }}>
                        <BootstrapTable
                          {...props.baseProps}
                          rowStyle={rowstyle}
                          striped
                          bootstrap4
                          condensed
                          rowClasses={rowClasses}
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>

      <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark" />
    </div>
  );
}
