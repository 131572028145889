import React, { useState, useEffect, useRef } from "react";
import Select from "../alerts/select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Avatar } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import went_wrong_toast from "../alerts/went_wrong_toast";
import Update_button from "../buttons/update_button";
import { ToastContainer } from "react-toastify";
import Select_field from "../alerts/select";

function Expensesupdate({
  show,
  onHide,
  data,
  user,
  route,
  fun,
  callback,
  current_user,
  RemoveUser_fun,
}) {
  const [date, setdate] = useState(data.date);
  const [remarks, setremarks] = useState(data.remarks);
  const [amount, setamount] = useState(data.amount);
  const [alltypes, setalltypes] = useState([]);
  const [type, settype] = useState({ value: data.type, label: data.type_name });

  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    const fetchWorkouts = async () => {
      var url = `${route}/api/expense-types/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setalltypes(optimize);
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisloading(true);

    const formData = new FormData();
    formData.append("date", date);

    formData.append("amount", amount);
    formData.append("remarks", remarks);

    formData.append("type", type.value);

    const response = await fetch(`${route}/api/expenses/${data.id}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formData,
    });
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      went_wrong_toast();
    }

    if (response.ok) {
      setisloading(false);
      callback({ type: "Update_table_history", data: json });
      onHide();
      fun("Update");
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: "0.85" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <ApartmentIcon className="me-2" />
          Add Expense
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <TextField
                type="Date"
                className="form-control  mb-3"
                id="outlined-basic"
                label="date"
                value={date}
                onChange={(e) => setdate(e.target.value)}
                size="small"
                required
                autoFocus
              />
            </div>
            <div className="col-md-6">
              <Select_field
                options={alltypes}
                placeholder="Types"
                value={type}
                funct={(e) => settype(e)}
                required={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField
                type="number"
                className="form-control  mb-3"
                id="outlined-basic"
                label="Amount"
                value={amount}
                onChange={(e) => setamount(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                multiline
                className="form-control  mb-3"
                id="outlined-basic"
                label="remarks"
                value={remarks}
                onChange={(e) => setremarks(e.target.value)}
                size="small"
                required
              />
            </div>
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Update_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Expensesupdate;
