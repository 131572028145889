import React, { useState, useEffect, useRef } from "react";
import "./booking.css";
import Button from "react-bootstrap/Button";
import { IconButton } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import Spinner from "react-bootstrap/Spinner";
import went_wrong_toast from "../alerts/went_wrong_toast";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { ToastContainer } from "react-toastify";
import custom_toast from "../alerts/custom_toast";
import Alert_before_delete from "../../Container/alertContainer";
import Select_field from "../alerts/select";
import success_toast from "../alerts/success_toast";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";
import TextField from "@mui/material/TextField";
import Dropdown from "react-bootstrap/Dropdown";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";

function Sale_history(props) {
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const history = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const RemoveUser_fun = props.RemoveUser;
  const settable_data = props.Setsavedata;
  const { SearchBar } = Search;
  const setActiveTab = props.setActiveTab;
  const [delete_user, setdelete_user] = useState(false);
  const [url_to_delete, seturl_to_delete] = useState("");
  const [row_id, setrow_id] = useState("");
  const [isloading, setisloading] = useState(false);

  const [vehicle, setvehicle] = useState({
    value: "all",
    label: "All",
  });
  const [customer, setcustomer] = useState({
    value: "all",
    label: "All",
  });

  const [allvehicle, setallvehicle] = useState([]);
  const [allcustomer, setallcustomer] = useState([]);

  const [callagain, setcallagain] = useState(false);

  const [start_date, setstart_date] = useState(
    addMonths(new Date(), -1).toISOString().substring(0, 10)
  );
  const [end_date, setend_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [date_range, setdate_range] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
      showDateDisplay: "false",
    },
  ]);

  const handleSelect = (item) => {
    const get_date = item.selection;
    setdate_range([item.selection]);
    setstart_date(get_date.startDate.toISOString().substring(0, 10));
    setend_date(get_date.endDate.toISOString().substring(0, 10));
    if (
      get_date.startDate.toISOString().substring(0, 10) !==
      get_date.endDate.toISOString().substring(0, 10)
    ) {
      setshow(!show);
    }
  };

  useEffect(() => {
    const fetchcustomers = async () => {
      var url = `${route}/api/parties/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        optimize.splice(0, 0, { value: "all", label: "All" });
        setallcustomer(optimize);
      }
      if (!response.ok) {
        went_wrong_toast();
        setisloading(false);
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }
    };

    const fetchvehicles = async () => {
      var url = `${route}/api/Vehicles/?user_id=${current_user.id}`;

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        optimize.splice(0, 0, { value: "all", label: "All" });
        setallvehicle(optimize);
      }
      if (!response.ok) {
        went_wrong_toast();
        setisloading(false);
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }
    };

    fetchvehicles();
    fetchcustomers();
  }, []);

  useEffect(() => {
    setisloading(true);
    dispatch({ type: "Set_menuitem", data: "sale_history" });

    const fetchProducts = async () => {
      var url = `${route}/api/bookings/?user_id=${current_user.id}&start_date=${start_date}&end_date=${end_date}`;
      if (
        date_range[0].endDate.getFullYear() -
          date_range[0].startDate.getFullYear() ===
        10
      ) {
        url = `${route}/api/bookings/?user_id=${current_user.id}`;
      }

      if (customer.value !== "all") {
        url = `${url}&customer=${customer.value}`;
      }
      if (vehicle.value !== "all") {
        url = `${url}&vehicle=${vehicle.value}`;
      }
      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        dispatch({ type: "Set_table_history", data: json });
      }
      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
        if (json.code === "token_not_valid") {
          RemoveUser_fun();
        }
      }
    };

    if (user) {
      fetchProducts();
    }
  }, [callagain, selected_branch]);

  const handleconfirm = (row) => {
    dispatch({ type: "Delete_table_history", data: { id: row } });
    custom_toast("Delete");
  };

  const dropdown_options_1 = ["Pending", "Out", "Close", "Pending Payment"];

  const handledropdown = async (e, id) => {
    const status = e.target.innerText;

    const formdata = new FormData();
    formdata.append("status", status);

    const response = await fetch(`${route}/api/bookings/${id}/update-status/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formdata,
    });
    const json = await response.json();

    if (!response.ok) {
      went_wrong_toast();
    }

    if (response.ok) {
      success_toast();
      setcallagain(!callagain);
    }
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex" style={{ width: "10rem" }}>
        <IconButton
          className="border border-primary rounded me-2"
          onClick={() => {
            localStorage.setItem("data", JSON.stringify(row));
            window.open("/invoice/sales", "_blank");
          }}
        >
          <PrintRoundedIcon className="m-1" color="primary" fontSize="medium" />
        </IconButton>

        <IconButton
          className="border border-danger rounded me-2"
          onClick={() => {
            setrow_id(row.id);
            seturl_to_delete(`${route}/api/bookings/${row.id}/`);
            setdelete_user(true);
          }}
        >
          <DeleteRoundedIcon className="m-1" color="error" fontSize="medium" />
        </IconButton>

        <IconButton
          className="p-0 me-2"
          style={{ border: "1px solid #003049", borderRadius: "5px" }}
          onClick={() => {
            const data = history.filter((item) => {
              return item.id === row.id;
            });
            settable_data({
              type: "Set_save_data",
              data: data[0],
            });
            setActiveTab("sale_Edit");
          }}
        >
          <EditOutlinedIcon
            className="m-1"
            style={{ color: "#003049" }}
            fontSize="medium"
          />
        </IconButton>

        <span>
          <Dropdown className="mydropdown">
            <Dropdown.Toggle variant="link" id={`dropdown-${row.id}`}>
              <MoreVertIcon
                className="p-0"
                style={{ color: "#003049" }}
                fontSize="medium"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdown_options_1.map((item) => (
                <Dropdown.Item
                  onClick={(e) => {
                    handledropdown(e, row.id);
                  }}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </span>
    );
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const check_status = (cell, row, rowIndex, formatExtraData) => {
    if (cell?.toLowerCase() === "pending") {
      return <span className="text-primary">{cell}</span>;
    } else if (cell?.toLowerCase() === "close") {
      return <span className="text-danger">{cell}</span>;
    } else if (cell?.toLowerCase() === "out") {
      return <span className="text-success">{cell}</span>;
    } else {
      return <span>{cell}</span>;
    }
  };

  const pending_amount = (cell, row) => {
    var total_ = row.total;
    var amount_received_ = cell;

    if (!total_) {
      total_ = 0;
    }
    if (!amount_received_) {
      amount_received_ = 0;
    }
    return <div>{total_ - amount_received_}</div>;
  };

  const dater = (cell, row) => {
    if (cell) {
      const splitted_array = cell.split("-");

      return (
        <span>
          {splitted_array[2]}/{splitted_array[1]}/{splitted_array[0]}
        </span>
      );
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
      headerFormatter: headerstyle,
      csvExport: false,
      footer: "",
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: dater,
      headerFormatter: headerstyle,
      footer: "",
    },
    {
      dataField: "from_date",
      text: "From",
      sort: true,
      formatter: dater,
      headerFormatter: headerstyle,
      footer: "",
    },
    {
      dataField: "to_date",
      text: "To",
      sort: true,
      formatter: dater,
      headerFormatter: headerstyle,
      footer: "",
    },
    {
      dataField: "booking_number",
      text: "Booking No.",
      sort: true,
      headerFormatter: headerstyle,
      footer: "",
    },
    {
      dataField: "product_info.name",
      text: "Vehicle",
      sort: true,
      headerFormatter: headerstyle,
      footer: "",
    },
    {
      dataField: "customer_info.name",
      text: "Customer",

      sort: true,
      headerFormatter: headerstyle,
      footer: "",
    },
    {
      dataField: "reference_person_info.name",
      text: "Reference",
      sort: true,
      headerFormatter: headerstyle,
      footer: "",
    },

    {
      dataField: "destination",
      text: "Destination",
      sort: true,
      headerFormatter: headerstyle,
      footer: "Total",
    },

    {
      dataField: "total",
      text: "Total",
      sort: true,
      headerFormatter: headerstyle,
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "amount_recieved",
      text: "Received",
      sort: true,
      headerFormatter: headerstyle,
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "amount_recieved",
      text: "Remaining",
      sort: true,
      formatter: pending_amount,
      headerFormatter: headerstyle,
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: check_status,
      headerFormatter: headerstyle,
    },

    {
      dataField: "Edit",
      text: "Action",
      formatter: linkFollow,
      headerFormatter: headerstyle,
      csvExport: false,
    },
  ];

  const handleselectiochange = (e) => {
    setshow(!show);
    setTarget(e.target);
  };

  useEffect(() => {
    setcallagain(!callagain);
  }, [vehicle, customer, date_range]);

  const rowstyle = { height: "10px" };

  return (
    <div className="p-3">
      <ToolkitProvider
        keyField="id"
        data={history}
        columns={columns}
        search
        exportCSV
      >
        {(props) => (
          <div className="card ">
            <div className="row d-md-flex align-items-start mt-2 p-2">
              <div className="col-6 col-sm-2  ">
                {date_range[0].endDate.getFullYear() -
                  date_range[0].startDate.getFullYear() ===
                10 ? (
                  <TextField
                    ref={ref}
                    type="button"
                    className="form-control  mb-3"
                    label="Date"
                    value="From Start"
                    onClick={handleselectiochange}
                    size="small"
                  />
                ) : (
                  <TextField
                    ref={ref}
                    type="button"
                    className="form-control  mb-3 "
                    label="Date"
                    value={`${date_range[0].startDate
                      .toLocaleString("en-GB")
                      .substring(0, 10)} - ${date_range[0].endDate
                      .toLocaleString("en-GB")
                      .substring(0, 10)}`}
                    onClick={handleselectiochange}
                    size="small"
                  />
                )}
                <Overlay
                  show={show}
                  target={target}
                  placement="bottom-start"
                  container={ref}
                >
                  <Popover id="popover-contained" className="pop_over">
                    <Popover.Body>
                      <div>
                        <DateRangePicker
                          onChange={handleSelect}
                          showSelectionPreview={true}
                          showCalendarPreview={false}
                          dragSelectionEnabled={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={date_range}
                          direction="horizontal"
                          preventSnapRefocus={true}
                          calendarFocus="backwards"
                          staticRanges={[
                            ...defaultStaticRanges,
                            {
                              label: "Last Year",
                              range: () => ({
                                startDate: startOfYear(
                                  addYears(new Date(), -1)
                                ),
                                endDate: endOfYear(addYears(new Date(), -1)),
                              }),
                              isSelected(range) {
                                const definedRange = this.range();
                                return (
                                  isSameDay(
                                    range.startDate,
                                    definedRange.startDate
                                  ) &&
                                  isSameDay(range.endDate, definedRange.endDate)
                                );
                              },
                            },
                            {
                              label: "This Year",
                              range: () => ({
                                startDate: startOfYear(new Date()),
                                endDate: endOfDay(new Date()),
                              }),
                              isSelected(range) {
                                const definedRange = this.range();
                                return (
                                  isSameDay(
                                    range.startDate,
                                    definedRange.startDate
                                  ) &&
                                  isSameDay(range.endDate, definedRange.endDate)
                                );
                              },
                            },
                            {
                              label: "From Start",
                              range: () => ({
                                startDate: startOfYear(
                                  addYears(new Date(), -10)
                                ),
                                endDate: endOfDay(new Date()),
                              }),
                              isSelected(range) {
                                const definedRange = this.range();
                                return (
                                  isSameDay(
                                    range.startDate,
                                    definedRange.startDate
                                  ) &&
                                  isSameDay(range.endDate, definedRange.endDate)
                                );
                              },
                            },
                          ]}
                        />
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
              <div className="col-6 col-sm-2 ">
                <Select_field
                  options={allcustomer}
                  placeholder="Customer"
                  value={customer}
                  funct={(e) => setcustomer(e)}
                />
              </div>
              <div className="col-6 col-sm-2 ">
                <Select_field
                  options={allvehicle}
                  placeholder="Vehicles"
                  value={vehicle}
                  funct={(e) => setvehicle(e)}
                />
              </div>
            </div>
            <div className=" text-end ps-3 me-3 pb-3">
              <SearchBar {...props.searchProps} />
            </div>

            {isloading && (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            )}

            <div
              className="ps-3 pe-3"
              style={{ minHeight: "70vh", zoom: "0.8" }}
            >
              <BootstrapTable
                {...props.baseProps}
                rowStyle={rowstyle}
                striped
                bootstrap4
                condensed
                wrapperClasses="table-responsive"
                classes="history-table"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>

      {delete_user && (
        <Alert_before_delete
          show={delete_user}
          onHide={() => setdelete_user(false)}
          url={url_to_delete}
          dis_fun={handleconfirm}
          row_id={row_id}
        />
      )}
      <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default Sale_history;
